<template>
  <div class="navbar-container main-menu-content">
    <horizontal-nav-menu-items
      :items="$route.meta.backend ? backEndNavMenuItems : navMenuItems"
    />
  </div>
</template>

<script>
import backEndNavMenuItems from "@/navigation/horizontal/backend.js";
import navMenuItems from "@/navigation/horizontal";
import HorizontalNavMenuItems from "./components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue";

export default {
  components: {
    HorizontalNavMenuItems,
  },
  setup() {
    return {
      backEndNavMenuItems,
      navMenuItems,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>

export default [
  {
    title: "Registra nuovo utente",
    icon: "UserIcon",
    route: "user-register",
  },
  {
    title: "Cerca Cliente",
    icon: "SearchIcon",
    route: "customer-search",
  },
  // {
  //     title: 'Help Desk',
  //     icon: 'MessageSquareIcon',
  //     route: 'open_ticket',
  // },
];

<template>
  <layout-horizontal>
    <template #navbar="{ toggleVerticalMenuActive }">
      <div
        class="px-1 d-flex justify-content-between align-items-center flex-grow-1"
      >
        <ul class="nav navbar-nav d-xl-none mr-2">
          <li class="nav-item">
            <b-link class="nav-link" @click="toggleVerticalMenuActive">
              <feather-icon icon="MenuIcon" size="21" />
            </b-link>
          </li>
        </ul>
        <div
          class="d-flex align-items-center cursor-pointer"
          @click="$router.push('/dashboard')"
        >
          <img src="@/assets/images/multicedi-logo.svg" />
          <span class="text-primary font-bold ml-1">
            <strong>Gateway</strong>
          </span>
        </div>

        <div v-if="!$route.meta.backend">
          <img
            height="40"
            :src="
              require('@/assets/images/' + user.user.company_name + '-logo.svg')
            "
          />
        </div>

        <b-nav>
          <b-nav-item-dropdown
            right
            no-caret
            toggle-class="d-flex align-items-center"
          >
            <template #button-content>
              <div
                v-if="!$route.meta.backend"
                class="d-sm-flex flex-column d-none text-dark text-right mr-1"
              >
                <p class="font-weight-bolder mb-0">
                  {{ user.user.user_name }} {{ user.user.user_surname }}
                </p>
                <span class="user-status" v-html="user.user.company_name_label"></span>
              </div>
              <div
                v-else
                class="d-sm-flex flex-column d-none text-dark text-right mr-1"
              >
                <p class="font-weight-bolder mb-0">Amministratore</p>
              </div>
              <b-avatar size="40" variant="primary">
                <feather-icon icon="UserIcon" size="22" />
              </b-avatar>
            </template>

            <b-dropdown-item
              link-class="d-flex align-items-center"
              @click="$route.meta.backend ? adminLogout() : logout()"
            >
              <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
              <span>Logout</span>
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-nav>
      </div>
    </template>

    <router-view />
  </layout-horizontal>
</template>

<script>
import LayoutHorizontal from "@core/layouts/layout-horizontal/LayoutHorizontal.vue";
import UserDropdown from "@core/layouts/components/app-navbar/components/UserDropdown.vue";
import { BLink, BNav, BNavItemDropdown, BDropdownItem, BAvatar } from "bootstrap-vue";
import { mapState } from "vuex";

export default {
  components: {
    LayoutHorizontal,
    UserDropdown,
    BDropdownItem,
    BAvatar,
    BNav,
    BNavItemDropdown,
    BLink
  },

  computed: {
    ...mapState(["user"]),
  },

  methods: {
    adminLogout() {
      localStorage.removeItem("admin_access_token");
      this.$router.push("/backend/login");
    },

    logout() {
      localStorage.removeItem("access_token");
      this.$router.push("/login");
    },
  },
};
</script>
